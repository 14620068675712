<template>
  <div>
    <b-card
      class="w-50 mx-auto"
    >
      <b-row
        align-v="center"
        class="mb-2"
      >
        <b-col>
          <b-link
            :to="{name: 'auth-login'}"
            class="text-secondary"
          >
            <feather-icon icon="ArrowLeftIcon" />
            Se connecter
          </b-link>
        </b-col>
      </b-row>
      <b-card-title
        class="mb-1 font-weight-bold"
        title-tag="h2"
      >
        {{ $route.params.token ? `Choix d'un nouveau mot de passe`: 'Récupération de mot de passe' }}
      </b-card-title>

      <validation-observer
        v-if="$route.params.token"
        ref="updatePasswordForm"
      >
        <b-form
          class="mt-2"
          @submit.prevent="updatePassword"
        >
          <!-- new password -->
          <b-form-group
            label="Mot de passe"
          >

            <validation-provider
              #default="{ errors }"
              name="Password"
              vid="password"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="recovery-password"
                  v-model="password"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="recovery-password"
                  placeholder="Password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- confirmation password -->
          <b-form-group
            label="Confirmation de mot de passe"
          >

            <validation-provider
              #default="{ errors }"
              name="Confirmation"
              vid="password_confirmation"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="recovery-password-confirmation"
                  v-model="password_confirmation"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  :type="passwordFieldType"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Token"
              vid="reset_password_token"
            >
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- submit buttons -->
          <b-button
            type="submit"
            variant="primary"
            block
          >
            Envoyer
          </b-button>
        </b-form>
      </validation-observer>
      <validation-observer
        v-else
        ref="recoveryPasswordForm"
      >
        <b-form
          class="mt-2"
          @submit.prevent="recovery"
        >
          <!-- email -->
          <b-form-group
            label="Email"
            label-for="recovery-email"
          >
            <validation-provider
              #default="{ errors }"
              name="Email"
              vid="email"
            >
              <b-form-input
                id="recovery-email"
                v-model="userEmail"
                :state="errors.length > 0 ? false:null"
                name="recovery-email"
                placeholder="john@exemple.com"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- submit buttons -->
          <b-button
            type="submit"
            variant="primary"
            block
          >
            Envoyer
          </b-button>
        </b-form>
      </validation-observer>

    </b-card>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow, BCol, BCard, BLink, BFormGroup, BFormInput, BForm, BButton, BInputGroupAppend, BInputGroup, BCardTitle
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BCardTitle,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCard,
    BForm,
    BLink,
    BButton,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: "test.agriprogress@gmail.com",
      password: "",
      password_confirmation: ""

    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    }
  },
  methods: {
    recovery() {
      this.$http.RecoverPassword(null, { email: this.userEmail })
        .then(() => {
          this.$router.replace({ name: "auth-login" });
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Email envoyé !",
              icon: "MailIcon",
              variant: "success",
              text: "Un email vous a été envoyé avec la procédure de récupération de mot de passe"
            }
          }, {
            timeout: 8000
          });
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            this.$refs.recoveryPasswordForm.setErrors(response.data.errors);
          }
        });
    },
    updatePassword() {
      this.$http.UpdatePasswordFromRecovery(null, {
        reset_password_token: this.$route.params.token,
        password: this.password,
        password_confirmation: this.password_confirmation
      })
        .then(() => {
          this.$router.replace({ name: "home" });
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "",
              icon: "MailIcon",
              variant: "success",
              text: "Votre mot de passe a été mis à jour vous pouvez maintenant vous connecter."
            }
          }, {
            timeout: 8000
          });
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            this.$refs.updatePasswordForm.setErrors(response.data.errors);
          }
        });
    }
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
